/** @format */

const articleRouter = [
  {
    path: 'article',
    name: 'Article',
    component: () => import('@/views/Article/Article'),
  },
  {
    path: 'article/:type/page-:page',
    name: 'ArticlePage',
    component: () => import('@/views/Article/Article'),
  },
  {
    path: 'article/:id',
    name: 'ArticleDetail',
    component: () => import('@/views/Article/ArticleDetail'),
  },
]
export default articleRouter
