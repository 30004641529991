/** @format */
const contractorRouter = [
  {
    path: 'contractor',
    name: 'Contractor',
    component: () => import('@/views/Contractor/Contractor'),
  },
  {
    path: 'contractor-detail/:id',
    name: 'ContractorDetail',
    component: () => import('@/views/Contractor/ContractorDetail'),
  },
  {
    path: 'contractor-portfolio-detail/:id',
    name: 'PortfolioDetail',
    component: () => import('@/views/Contractor/PortfolioDetail'),
  },
  {
    path: 'contractor-review-company/:id',
    name: 'ReviewCompany',
    component: () => import('@/views/Contractor/ReviewCompany'),
  },
  {
    path: 'contractor-view-editor/:id',
    name: 'EditContractorView',
    component: () => import('@/views/Contractor/EditContractorView'),
    meta: { requiresAuth: true },
  },
]
export default contractorRouter
