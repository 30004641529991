/** @format */

const cartRouter = [
  {
    path: 'cart',
    name: 'Cart',
    component: () => import('@/views/Cart/Cart'),
    meta: { requiresAuth: true },
  },
  {
    path: 'order-confirmation',
    name: 'Order Confirmation',
    component: () => import('@/views/Cart/OrderConfirmation'),
    meta: { requiresAuth: true },
  },
  {
    path: 'design-download/:id',
    name: 'Design Download',
    component: () => import('@/views/Cart/DesignDownload'),
    meta: { requiresAuth: true },
  },
]
export default cartRouter
