import store from "@/store";
import axiosClient from "../plugins/axiosClient";

export default {
  namespaced: true,
  state: {
    categoryList: [],
  },
  getters: {
    categories: (state) => {
        let arr = [
            {
              categoryId: 0,
              name: "Tất cả",
              nameEn: "All",
              nameKo: "전체",
            },
          ];
       return arr.concat(state.categoryList);
    },
  },
  mutations: {
    GET_CATEGORY_LIST(state, payload) {
      state.categoryList = payload ? payload : null;
    },
  },
  actions: {
    // getBannerSlide
    async getCategoryList({ commit, state, dispatch }) {
      try {
        const res = await axiosClient.get(
          `${store.state.url_mall}/api/public/category/category-list`,
          { headers: { "Accept-Language": store.state.lang } }
        );
        if (res && res.data) {
            commit("GET_CATEGORY_LIST", res.data.categories);
        }
      } catch (error) {
        throw error;
      }
    },
  },
};
