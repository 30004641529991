<template>
  <div id="app">
    <vue-extend-layouts />
  </div>
</template>
<style lang="scss">
// @font-face {
//   font-family: "Quicksand";
//   src: local("Quicksand"),
//    url(../assets/Quicksand/Quicksand-VariableFont_wght.ttf) format("truetype");
// }
</style>
<script>
import VueExtendLayouts from "vue-extend-layout";
export default {
  name: "App",
  components: { VueExtendLayouts },
};
</script>
