import store from "@/store";
import axiosClient from "../plugins/axiosClient";
import qs from "querystringify";

export default {
  namespaced: true,
  state: {
    interiorShare: [],
    otherInteriorShare: []
  },
//   getters: {
//     getInfoToken: (state) => {
//       return state.token;
//     },
//     getBaseURL: (state) => {
//       return state.urlApi;
//     }
//   },
  mutations: {
    GET_INTERIOR_SHARE_LIST(state, payload) {
      state.interiorShare = payload ? payload : null;
    },
    GET_OTHER_INTERIOR_SHARE_LIST(state, payload) {
      state.otherInteriorShare = payload ? payload : null;
    },
  },
  actions: {
    // getBestFurnitureDecorList
    async getInteriorShareList({ commit, state, dispatch }) {
      try {
        const res = await axiosClient.get(
          `${store.state.urlApi}/app/interior-share/list?page=1&limit=9`,
          { headers: { "Accept-Language": store.state.lang } }
        );
        if (res && res.data) {
            let list = [];
            if (window.innerWidth <= 992) {
                let isEven = res.data.data.length % 2;
                list = res.data.data.slice(
                  0,
                  res.data.data.length - isEven
                );
            } else if (res.data && res.data.data.length > 0)
                if (res.data.data.length < 9 && res.data.data.length >= 6) {
                  list = res.data.data.slice(0, 6);
                } else if (
                  res.data.data.length < 6 &&
                  res.data.data.length >= 3
                ) {
                  list = res.data.data.slice(0, 3);
            } else {
                list = res.data.data;
            }
            commit("GET_INTERIOR_SHARE_LIST", list);
        }
      } catch (error) {
        throw error;
      }
    },
    async getOtherInteriorShareList({ commit, state, dispatch }, payload) {
      try {
        const res = await axiosClient.get(
          `${store.state.urlApi}/app/interior-share/list?${qs.stringify(payload)}`,
          { headers: { "Accept-Language": store.state.lang } }
        );
        if (res && res.data) {
          console.log("my-other-data", res.data.data)
          commit("GET_OTHER_INTERIOR_SHARE_LIST", res.data.data);
        }
      } catch (error) {
        throw error;
      }
    },
  },
};
