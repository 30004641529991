/** @format */

const brandRouter = [
  {
    path: 'brand/design-list',
    name: 'DesignBrandList',
    component: () => import('@/views/Brand/DesignBrandList'),
  },
    {
      path: 'brand/:id',
      name: 'BrandList',
      component: () => import('@/views/Brand/BrandList'),
    },
    // {
    //   path: 'design-download/:id',
    //   name: 'Design Download',
    //   component: () => import('@/views/Cart/DesignDownload'),
    //   meta: { requiresAuth: true },
    // },
  ]
  export default brandRouter
  