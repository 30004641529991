<!-- @format -->

<template>
  <div class="login mx-auto text-center">
    <p class="login-title">
      {{ $t("MENU.login") }}
    </p>
    <b-form class="login-form" @submit.stop.prevent="onSubmit">
      <b-form-group class="has-float-label" :class="{ 'is-invalid': $v.form.email.$error }">
        <b-form-input id="email" type="email" text="text" v-model.trim="$v.form.email.$model"
          placeholder="Email"></b-form-input>
        <label class="fl-label" for="email"> {{ $t("HOME.INFORMATION.email") }} * </label>
        <p class="validation" :class="{ 'validation-active': $v.form.email.$error }" v-if="!$v.form.email.required">
          {{ $t("MESSAGE.AUTH.email.required") }}
        </p>
        <p class="validation" :class="{ 'validation-active': $v.form.email.$error }" v-if="!$v.form.email.email">
          {{ $t("MESSAGE.AUTH.email.invalid") }}
        </p>
      </b-form-group>

      <b-form-group class="password has-float-label" :class="{ 'is-invalid': $v.form.password.$error }">
        <b-form-input id="password" v-model.trim="$v.form.password.$model" placeholder="Password" :type="pwType">
        </b-form-input>
        <label class="fl-label" for="password"> {{ $t("MY_PAGE.password") }}* </label>
        <p class="validation" :class="{ 'validation-active': $v.form.password.$error }" v-if="!$v.form.password.required">
          {{ $t("MESSAGE.AUTH.password.required") }}
        </p>
        <p class="validation" :class="{ 'validation-active': $v.form.password.$error }"
          v-if="!$v.form.password.minLength">
          {{ $t("MESSAGE.AUTH.password.minlength") }}
        </p>
        <b-icon v-if="isPw" icon="eye-slash" class="pw-control" @click="togglePwType"></b-icon>
        <b-icon v-else icon="eye" class="pw-control" @click="togglePwType"></b-icon>
      </b-form-group>
      <p class="forgot-pw" @click="$router.push({ name: 'Forgot Password' })">
        {{ $t("AUTH.LOGIN.forgot_password") }}
      </p>
      <div class="d-flex justify-content-between">
        <b-button type="reset" class="btn-app btn-dark mr-2" @click="$router.push({ name: 'Main' })">
          {{ $t("AUTH.CHOOSE_USER_TYPE.later") }}
        </b-button>
        <b-button type="submit" :disabled="$v.$invalid" class="btn-app btn-dark ml-2">
          {{ $t("AUTH.LOGIN.login") }}
        </b-button>
      </div>
    </b-form>
    <div class="strike">
      <span>or</span>
    </div>
    <SocialLogin />
    <div class="rules">
      <p>
        <span class="mb-0">
          {{ $t("AUTH.REGISTER.by_creating_an_account") }}
        </span>
        <router-link class="app-link" :to="`/${$i18n.locale}/term-detail/${'Privacy Policy'}`">
          {{ $t("AUTH.REGISTER.terms_of_service") }}
        </router-link>
        <span>{{ $t("AUTH.REGISTER.you_will_agree_to") }}</span>
      </p>
    </div>
    <p class="my-3">
      {{ $t("AUTH.LOGIN.dont_have_account_yet") }}
      <router-link class="app-link" :to="`/${$i18n.locale}/sign-up`">
        {{ $t("AUTH.REGISTER.sign_up") }}
      </router-link>
  </p>
  <!-- <select v-model="languageSelected" class="select-language text-center">
      <option value="en">English</option>
        <option value="vn">Vietnamese</option>
      </select> -->
  </div>
</template>
<style lang="scss" scoped>
@import "~sass-rem";
@import "../assets/scss/login.scss";
</style>
<script>
import SocialLogin from "@/components/SocialLogin";
import { required, minLength, email } from "vuelidate/lib/validators";
export default {
  name: "Login",
  components: {
    SocialLogin,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      // languageSelected: 'en',
      isPw: true,
      pwType: "password",
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  created() { },
  methods: {
    onSubmit() {
      this.$store
        .dispatch("auth/signIn", this.form)
        .then((res) => {
          if (res) {
            this.$notify({
              title: "Success",
              text: "Login success",
              type: "success",
            });
            this.$router.back();

            // -------------------
            this.pushMixPanel("Login", {
              user_id: res?.id || null,
            })
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // this.$store
      // .dispatch("auth/getTokenCommerce")
      // .then((res) => {
      //   console.log("res", res)
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
    },
    togglePwType() {
      this.isPw = !this.isPw;
      this.pwType = this.isPw ? "password" : "text";
    },
  },
  mounted() { },
  watch: {},
};
</script>
