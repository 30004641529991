/** @format */

const packageRouter = [
  {
    path: "package/design-list/:id",
    name: "PackageDesignList",
    component: () => import("@/views/Package/PackageDesignList.vue"),
  },
  {
    path: "brand/:id",
    name: "BrandList",
    component: () => import("@/views/Brand/BrandList"),
  },
  // {
  //   path: "package/combo-list/:id",
  //   name: "ComboPackageList",
  //   component: () => import("@/views/Package/ComboPackageList.vue"),
  // },
  // {
  //   path: 'v2/package/combo-list/:id',
  //   name: 'ComboPackageListV2',
  //   component: () => import('@/views/Package/ComboPackageListV2.vue'),
  // }
  {
    path: "package/combo-list/:id",
    name: "ComboPackageListV2",
    component: () => import("@/views/Package/ComboPackageListV2.vue"),
  },
  // {
  //   path: 'design-download/:id',
  //   name: 'Design Download',
  //   component: () => import('@/views/Cart/DesignDownload'),
  //   meta: { requiresAuth: true },
  // },
];
export default packageRouter;
