const forgotRouter = [
  {
    path: 'forgot-password',
    name: 'Forgot Password',
    component: () => import('@/views/ForgotPassword/ForgotPassword')
  },
  {
    path: 'verify-code',
    name: 'Forgot Password Verify Code',
    component: () => import('@/views/ForgotPassword/VerifyCode')
  },
  {
    path: 'change-password-success',
    name: 'Change Password Success',
    component: () => import('@/views/ForgotPassword/Success')
  }
];
export default forgotRouter;
