const contractorADRouter = [
  {
    path: 'ad-membership',
    name: 'ContractorAD',
    component: () => import('@/views/ContractorAD/ContractorAD')
  },
  {
    path: 'ad-membership/auction',
    name: 'PremiumAuction',
    component: () => import('@/views/ContractorAD/PremiumAuction'),
    meta: { requiresAuth: true }
  },
  {
    path: 'ad-membership/my-profile/:membershipType',
    name: 'MyProfileContractor',
    component: () => import('@/views/ContractorAD/MyProfileContractor'),
    meta: { requiresAuth: true }
  },
  {
    path: 'ad-membership/order-confirmation/:membershipType',
    name: 'ADOrderConfirmation',
    component: () => import('@/views/ContractorAD/ADOrderConfirmation'),
    meta: { requiresAuth: true }
  },
  {
    path: 'ad-membership/contractor-membership-success',
    name: 'ContractorMembershipSuccess',
    component: () => import('@/views/ContractorAD/ContractorMembershipSuccess')
  }
];
export default contractorADRouter;
