const paymentRouter = [
  {
    path: "payment/:type/:id/:payType",
    name: "Payment",
    component: () => import("@/views/Payment/Payment"),
  },
  {
    path: "payment-result/:type",
    name: "PaymentResult",
    component: () => import("@/views/Payment/PaymentResult"),
  },
  {
    path: "payment-web-result/:type/:id",
    name: "PaymentWebResult",
    component: () => import("@/views/Payment/PaymentWebResult"),
    meta: { requiresAuth: true },
  },
  {
    path: "payment-success/:type",
    name: "PaymentSuccess",
    component: () => import("@/views/Payment/components/PaymentSuccess"),
  },
  {
    path: "payment-order-confirm",
    name: "PaymentOrderConfirm",
    component: () => import("@/views/Payment/components/PaymentOrderConfirm"),
  },
];
export default paymentRouter;
