/** @format */

const myPageRouter = [
  {
    path: "my-page",
    name: "MyPage",
    component: () => import("@/views/MyPage/MyPage"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/point-coupon",
    name: "PointCoupon",
    component: () => import("@/views/MyPage/PointCoupon"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/point-history",
    name: "PointHistory",
    component: () => import("@/views/MyPage/PointHistory"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/my-auction-result",
    name: "MyAuctionResult",
    component: () => import("@/views/MyPage/Contractor/MyAuctionResult"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/my-profile-customer",
    name: "MyProfileCustomer",
    component: () => import("@/views/MyPage/MyProfileCustomer"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/my-profile-business",
    name: "MyProfileBusiness",
    component: () => import("@/views/MyPage/MyProfileBusiness"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/my-profile-business-switch",
    name: "MyProfileBusinessSwitch",
    component: () => import("@/views/MyPage/MyProfileBusinessSwitch"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/premium-auction-result",
    name: "PremiumAuctionResult",
    component: () => import("@/views/MyPage/Contractor/PremiumAuctionResult"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/ad-purchased-detail",
    name: "ADPurchasedDetail",
    component: () => import("@/views/MyPage/Contractor/ADPurchasedDetail"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/portfolio-review-management/:type",
    name: "PortfolioReview",
    component: () => import("@/views/MyPage/Contractor/PortfolioReview"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/membership",
    name: "Membership",
    component: () => import("@/views/MyPage/Contractor/Membership"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/detail-statistics",
    name: "DetailStatistics",
    component: () => import("@/views/MyPage/Contractor/DetailStatistics"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/change-password",
    name: "ChangePassword",
    component: () => import("@/views/MyPage/ChangePassword"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/faq",
    name: "FAQ",
    component: () => import("@/views/MyPage/FAQ"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/inbox",
    name: "Inbox",
    component: () => import("@/views/MyPage/Inbox"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/customer-center",
    name: "CustomerCenter",
    component: () => import("@/views/MyPage/CustomerCenter"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/customer-center-post",
    name: "CustomerCenterPost",
    component: () => import("@/views/MyPage/CustomerCenterPost"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/order",
    name: "MyPageOrder",
    component: () => import("@/views/MyPage/Order"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/order/:status",
    name: "MyPagePendingOrder",
    component: () => import("@/views/MyPage/PendingOrder"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/bookmark",
    name: "MyPageBookmark",
    component: () => import("@/views/MyPage/Bookmark"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/contractor-review",
    name: "ContractorReview",
    component: () => import("@/views/MyPage/ContractorReview"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/review",
    name: "Review",
    component: () => import("@/views/MyPage/Review"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/shared-interior-design",
    name: "SharedInteriorDesign",
    component: () => import("@/views/MyPage/SharedInteriorDesign"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/order/order-detail/:id",
    name: "OrderDetail",
    component: () => import("@/views/MyPage/Contractor/OrderDetail"),
    meta: { requiresAuth: true },
  },
  {
    path: "my-page/order/order-shop-detail/:id",
    name: "OrderShopDetail",
    component: () => import("@/views/MyPage/Contractor/OrderShopDetail"),
    meta: { requiresAuth: false },
  },
  {
    path: "my-page/order/order-shop-detail-cancel/:id",
    name: "OrderShopDetailCancel",
    component: () => import("@/views/MyPage/Contractor/OrderShopDetailCancel"),
    meta: { requiresAuth: false },
  },
  {
    path: "my-page/order/order-shop-detail-refund/:id",
    name: "OrderShopDetailRefund",
    component: () => import("@/views/MyPage/Contractor/OrderShopDetailRefund"),
    meta: { requiresAuth: false },
  },
  {
    path: "my-page/order/order-shop-detail-refund-deny/:id",
    name: "OrderShopDetailRefundDeny",
    component: () =>
      import("@/views/MyPage/Contractor/OrderShopDetailRefundDeny"),
    meta: { requiresAuth: false },
  },
];
export default myPageRouter;
