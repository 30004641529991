/** @format */

const shopRouter = [
  {
    path: "shop",
    name: "Shop",
    component: () => import("@/views/Shop/Shop"),
  },
  {
    path: "essay",
    name: "Essay",
    component: () => import("@/views/Shop/Essay"),
  },
  {
    path: "essay/page-:page",
    name: "EssayPage",
    component: () => import("@/views/Shop/Essay"),
  },
  {
    path: "essay-detail/:id/:slug",
    name: "EssayDetail",
    component: () => import("@/views/Shop/EssayDetail"),
  },
  {
    path: "shop-detail/:id",
    name: "ShopDetail",
    component: () => import("@/views/Shop/ShopDetail"),
    meta: { requiresAuth: false },
  },
];

export default shopRouter;
