<template>
  <div class="send-email app-content">
    <p class="send-email-title">{{ $t("CORE.send_email") }}</p>
    <b-form class="send-email-form" @submit.stop.prevent="onSubmit">
      <div class="user-name">
        <span class="label">
          {{ $t("ADDRESS.receiver") }}
        </span>
        <span class="value" v-if="user_info && user_info.company_name">{{
          user_info.company_name
        }}</span>
      </div>

      <div class="user-name mt-2">
        <span class="label"> {{ $t("CUSTOMER_CENTER.contact") }}</span>
        <span class="value" v-if="user_info && user_info.user"
          >{{ user_info.user.first_name + " " + user_info.user.last_name }} (ID
          - {{ user_info.user_id }})</span
        >
      </div>
      <b-form-group>
        <b-form-input
          id="phone_number"
          type="text"
          v-model.trim="$v.form.phone_number.$model"
          :placeholder="$t('CONSTRUCTION_REVIEW.WRITE_REVIEW.phone')"
        ></b-form-input>
        <p
          class="validation"
          :class="{ 'validation-active': $v.form.phone_number.$error }"
          v-if="!$v.form.phone_number.strongPassword"
        >
          {{ $t("MESSAGE.AUTH.AUTH_16") }}
        </p>
        <p
          class="validation"
          :class="{ 'validation-active': $v.form.phone_number.$error }"
          v-if="!$v.form.phone_number.required"
        >
          {{ $t("CONSTRUCTION_REVIEW.WRITE_REVIEW.err_phone") }}
        </p>
      </b-form-group>
      <b-form-group>
        <b-form-input
          id="email"
          type="email"
          v-model.trim="$v.form.email.$model"
          :placeholder="$t('HOME.INFORMATION.email')"
        ></b-form-input>
        <p
          class="validation"
          :class="{ 'validation-active': $v.form.email.$error }"
          v-if="!$v.form.email.required"
        >
          {{ $t("MESSAGE.AUTH.email.required") }}
        </p>
        <p
          class="validation"
          :class="{ 'validation-active': $v.form.email.$error }"
          v-if="!$v.form.email.email"
        >
          {{ $t("MESSAGE.AUTH.email.invalid") }}
        </p>
      </b-form-group>

      <p class="label">{{ $t("CUSTOMER_CENTER.context") }}</p>
      <div class="context-container">
        <b-form-group>
          <b-form-textarea
            id="context"
            class="text-area"
            type="text"
            rows="3"
            v-model.trim="$v.form.context.$model"
          >
          </b-form-textarea>
          <p
            class="validation"
            :class="{ 'validation-active': $v.form.context.$error }"
            v-if="!$v.form.context.required"
          >
            {{ $t("MESSAGE.AUTH.AUTH_17") }}
          </p>
        </b-form-group>
        <div
          class="thumbnail custom-img"
          v-if="form.context.includes(shortLink) && linkThumbnail"
        >
          <img :src="linkThumbnail" alt="Thumbnail" />
        </div>
      </div>

      <UploadImage
        class="section-upload"
        @image-change="imageChange"
        @on-delete="handleDeleteImage"
      />

      <b-button
        type="submit"
        :disabled="$v.$invalid"
        class="btn-app btn-dark mr-2"
        >{{ $t("CUSTOMER_CENTER.save") }}</b-button
      >
    </b-form>
    <div class="send-email-content"></div>
  </div>
</template>
<style lang="scss" scoped>
@import "~sass-rem";
@import "@/assets/scss/send-email.scss";
</style>
<script>
import { required, email } from "vuelidate/lib/validators";
import UploadImage from "../components/UploadImage.vue";
export default {
  name: "SendEmail",
  components: {
    UploadImage,
  },
  data() {
    return {
      shortLink: localStorage.getItem("share_link") || null,
      linkThumbnail: localStorage.getItem("link_thumbnail") || null,
      form: {
        email: "",
        phone_number: "",
        context: localStorage.getItem("share_link") || "",
        files: [],
        urls: [],
      },
      images: [],
      user_info: null,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      phone_number: {
        required,
        strongPassword(phoneNumber) {
          return /[0-9]/.test(phoneNumber) && !/[a-z]/.test(phoneNumber);
        },
      },
      context: {
        required,
      },
    },
  },
  created() {
    this.getContractorDetail();
  },
  methods: {
    onSubmit() {
      if (this.images.length > 0) {
        this.handleSendMail();
      } else {
        this.sendMail();
      }
    },
    async getContractorDetail() {
      let response = await this.callAPI(
        `/app/contractor/detail/${this.$route.params.contractor_id}`,
        "get"
      );
      if (response && response.data && response.data.data) {
        this.user_info = response.data.data;
        this.form.email = this.user_info.email_contractor;
        this.form.phone_number = this.user_info.company_phone;
      }
    },
    async sendMail() {
      let arr = [];
      arr.push({ images: this.linkThumbnail });

      const body = {
        target_type: "business,constructor",
        contractor_id: this.$route.params.contractor_id || null,
        phone_number: this.form.phone_number,
        email: this.form.email,
        content: this.form.context,
        images: arr.concat([...this.form.files]).filter((e) => e.images),
      };
      let response = await this.callAPI("/app/inbox/upload", "post", body, {
        Authorization: `Bearer ${this.$store.state.auth.token}`,
      });
      console.log(response);
      if (response && response.data && response.data.data) {
        localStorage.removeItem("share_link");
        localStorage.removeItem("link_thumbnail");
        this.updateNumberOfEmail();
        this.$router.back();
      }
    },
    imageChange(value) {
      this.images.push(value);
    },
    handleDeleteImage(index) {
      this.images.splice(index, 1);
    },
    async handleSendMail() {
      //upload images
      const body = new FormData();
      this.images.forEach((item) => {
        if (item) body.append("files", item);
      });
      body.append("secret", "lifeon2022");
      body.append("folder", "inbox");
      let response = await this.callAPI("/aws/upload", "post", body);
      if (response && response.data && response.data.data) {
        this.form.files = response.data.data.map((item) => ({
          images: item,
        }));
        if (this.form.files.length > 0) {
          this.sendMail();
        }
      }
    },
    async updateNumberOfEmail() {
      const body = {
        contractor_id: this.$route.params.contractor_id,
        type: "number_of_emails",
        ad_type: localStorage.getItem("ad_type"),
      };
      let response = await this.callAPI(
        "/app/contractor/contractor-update-number-of-type",
        "post",
        body,
        {
          Authorization: `Bearer ${this.$store.state.auth.token}`,
        }
      );
    },
  },
  mounted() {},
  watch: {},
};
</script>
