<template>
  <div class="social-buttons">
    <div class="social-google">
      <div id="buttonDiv"></div>
      <p class="social-name pt-1 mb-0">Google</p>
    </div>
    <!-- <div class="social-item" @click="loginWithGoogle()">
      <img src="@/assets/images/icons/google.svg" alt="" />
      <p class="social-name pt-1 mb-0">Google</p>
    </div> -->
    <div class="social-item" @click="loginWithApple()">
      <img src="@/assets/images/icons/apple.svg" alt="" />
      <p class="social-name pt-1 mb-0">Apple ID</p>
    </div>
    <div>
      <vue-apple-login
        mode="center-align"
        ref="appleLogin"
        :onSuccess="onSuccess"
        :onFailure="onFailure"
        :style="{ display: 'none' }"
      >
      </vue-apple-login>
    </div>
    <div class="social-item" @click="loginWithFacebook()">
      <facebook-login
        style="display: none"
        class="fb-login"
        loginLabel=""
        appId="1104938286978039"
        @logout="onLogout"
        @login="loginWithFacebook()"
      ></facebook-login>
      <img src="@/assets/images/icons/facebook.svg" alt="" />
      <p class="social-name pt-1 mb-0">Facebook</p>
      <div id="fb-root"></div>
    </div>
    <div class="social-item" @click="loginWithZalo()">
      <img src="@/assets/images/icons/zalo-login.svg" alt="" />
      <p class="social-name pt-1 mb-0">Zalo</p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "~sass-rem";
@import "@/assets/scss/social-login.scss";
</style>
<script>
import { mapActions } from "vuex";
import facebookLogin from "facebook-login-vuejs";
import jwt_decode from "jwt-decode";
import pkceChallenge from "pkce-challenge";
import randomstring from "randomstring";
import axios from "axios";

export default {
  name: "social-login",
  components: { facebookLogin },
  created() {},
  mounted() {
    let routerBack = this.$router;
    let api = this.$store;
    let ggProfile = {};
    let vm = this;
    async function handleCredentialResponse(response) {
      let responsePayload = jwt_decode(response.credential);
      if (responsePayload?.sub) {
        api
          .dispatch("auth/signInGG", { social_id: responsePayload.sub })
          .then((res) => {
            //console.log(res);
            if (res) routerBack.back();
            else
              routerBack.push({
                name: "SignUpSocial",
                params: {
                  method: "google",
                  userid: responsePayload.sub,
                  email: responsePayload.email,
                },
              });
          })
          .catch((err) => {
            // console.log(err);
            // routerBack.push({
            //   name: "SignUpSocial",
            //   params: { method: "google", userid: responsePayload.sub, email: responsePayload.email },
            // });
            ggProfile.user_type = "customer";
            ggProfile.login_method = "google";
            (ggProfile.social_id = responsePayload.sub),
              (ggProfile.email = responsePayload.email || null);
            ggProfile.first_name = responsePayload.given_name;
            ggProfile.last_name = responsePayload.family_name;
            ggProfile.avatar = responsePayload.picture;
            vm.signUp(ggProfile)
              .then((res) => {
                api
                  .dispatch("auth/signInGG", { social_id: responsePayload.sub })
                  .then((res) => {
                    //console.log(res);
                    if (res) routerBack.back();
                  });
              })
              .catch((err2) => {
                console.log(err2);
                routerBack.push({
                  name: "SignUpSocial",
                  params: {
                    method: "google",
                    userid: responsePayload.sub,
                    email: responsePayload.email,
                  },
                });
              });
          });
      }
    }
    google.accounts.id.initialize({
      client_id: this.$store.state.MIX_GOOGLE_CLIENT_ID,
      callback: handleCredentialResponse,
    });
    google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      {
        type: "icon",
        shape: "circle",
        theme: "outline",
        text: "signin_with",
        size: "large",
        locale: "bs",
        logo_alignment: "center",
      }
      // customization attributes
    );
    // google.accounts.id.prompt(); // also display the One Tap dialog
  },
  methods: {
    ...mapActions({
      signinGG: "auth/signInGG",
      signInFaceBook: "auth/signInFaceBook",
      signInZalo: "auth/signInZalo",
      signInApple: "auth/signInApple",
      signUp: "auth/signUp",
    }),
    test: function () {
      //console.log("test success!");
    },
    onLogout() {
      return true;
    },
    onSuccess(data) {
      let send_data = {
        userID: data.userData.sub,
      };
      const appleProfile = {
        social_id: data.userData.sub,
        user_type: "customer",
        login_method: "apple",
        first_name: "Anonymous",
        last_name: ""
      }
      this.signInApple({ send_data: send_data })
        .then((res) => {
          // this.$notify({ title: "Success", text: "Login success", type: "success" });
          if (res && res.token) {
            // this.$router.push({
            //   name: "Main",
            // });
            this.$router.back();
          } else {
            this.signUpApple(appleProfile)
          }
        })
        .catch((err) => {
          // this.$router.push({
          //   name: "SignUpSocial",
          //   params: { method: "apple", userid: data.userData.sub },
          // });
          this.signUpApple(appleProfile)
        });
    },
    onSuccessLogout() {
      this.signedIn = false;
    },
    onFailure(error) {
      console.log(error);
    },

    //login Google
    async loginWithGoogle() {
      // this.$refs.googleLogin.$el.click();
      console.log(this.$refs);
    },
    //login apple
    loginWithApple() {
      this.$refs.appleLogin.$el.click();
    },
    //login Facebook
    async loginWithFacebook() {
      window.fbAsyncInit = function () {
        FB.init({
          appId: "1104938286978039",
          status: true,
          cookie: true,
          xfbml: true,
          version: "v17.0",
        });
      };
      (function () {
        var e = document.createElement("script");
        e.async = true;
        e.src =
          document.location.protocol + "//connect.facebook.net/en_US/all.js";
        document.getElementById("fb-root").appendChild(e);
      })();
      await FB.getLoginStatus(async (response) => {
        if (response.status === "connected") {
          await FB.api("/me/permissions", "delete", null, () => FB.logout());
        }
      });
      var vm = this;

      await FB.login(
        async (response) => {
          if (response.status === "connected") {
            const profile = {};
            vm.accessToken = response.authResponse.accessToken;
            vm.userID = response.authResponse.userID;
            let send_data = {
              userID: vm.userID,
              accessToken: vm.accessToken,
            };
            vm.signInFaceBook({ send_data: send_data })
              .then((res) => {
                // this.$notify({ title: "Success", text: "Login success", type: "success" });
                //FB.api('/me/permissions', 'delete', null, () => FB.logout());
                if (res && res.token) {
                  // this.$router.push({
                  //   name: "Main",
                  // });
                  this.$router.back();
                }
              })
              .catch(async (err) => {
                await FB.api(
                  "/me",
                  { fields: "id,name,first_name,last_name,email,picture" },
                  function (response) {
                    profile.user_type = "customer";
                    profile.login_method = "facebook";
                    (profile.social_id = response.id),
                      (profile.email = response.email || null);
                    profile.first_name = response.first_name;
                    profile.last_name = response.last_name;
                    profile.avatar = response.picture?.data?.url;
                    vm.signUp(profile)
                      .then((res) => {
                        vm.signInFaceBook({ send_data: send_data }).then(
                          (result) => {
                            //console.log("result1234567: ", result);
                            if (result && result.token) {
                              vm.$router.back();
                            }
                          }
                        );
                      })
                      .catch((err2) => {
                        //console.log("err2: ", err2);
                      });
                  }
                );
              });
            return;
          }
          //console.log("User login failed");
        },
        { scope: "public_profile,email" }
      );
    },
    //login zalo
    async loginWithZalo() {
      const pkceChallenge = require("pkce-challenge").default;
      let codeGenerator = pkceChallenge();
      let state = randomstring.generate(8);
      //zaloID
      const zsConfig = {
        appId: "2551556789795094841",
        redirectUri: `${this.$store.state.domain}`,
        //redirectUri: "https://life-on.me",
        //redirectUri: "https://dev2.lifeonkorea.com/",
        appSecret: "Kk43Fqd1awXHqIs5L108",
      };
      //const urlPermission = `https://oauth.zaloapp.com/v3/permission?app_id=${zsConfig.appId}&redirect_uri=${zsConfig.redirectUri}&state=active`;
      const urlPermission = `https://oauth.zaloapp.com/v4/permission?app_id=${zsConfig.appId}&redirect_uri=${zsConfig.redirectUri}&code_challenge=${codeGenerator.code_challenge}&state=${state}`;
      window.open(
        urlPermission,
        "zalo-login",
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
          350 +
          ", height=" +
          500 +
          ", top=" +
          Number(screen.height / 2 - 500 / 1) +
          ", left=" +
          Number(screen.width / 2 - 350 / 2)
      );
      this.helper.zaloToken = (res) => {
        let paramUrl = res.split("");
        const firstData = paramUrl.splice(6);
        firstData.splice(firstData.indexOf("&"));
        firstData.splice(0, firstData.indexOf("=") + 1);
        const codeResponse = firstData.join("");
        const params = {
          app_id: zsConfig.appId,
          //app_secret: zsConfig.appSecret,
          secret_key: zsConfig.appSecret,
          grant_type: "authorization_code",
          code: codeResponse,
          code_verifier: codeGenerator.code_verifier,
        };
        this.$store.state.isLoading = true;
        axios
          .post(`${this.$store.state.urlApi}/app/zalo/access_token`, params)
          .then((res) => {
            this.getInfoZalo(res.data.data.access_token);
          })
          .catch((error) => {
            console.log(error.message);
          });
      };
    },
    //login get info Zalo
    async getInfoZalo(data) {
      await axios
        .get(`${this.$store.state.urlApi}/app/zalo/me?access_token=${data}`)
        .then((res) => {
          let send_data = {
            userID: res.data.data.id,
          };
          const zaloProfile = {
            first_name: res.data.data.name,
            last_name: "",
            login_method: "zalo",
            social_id: res.data.data.id,
            user_type: "customer",
          };
          this.signInZalo({ send_data: send_data })
            .then((res) => {
              // this.$notify({ title: "Success", text: "Login success", type: "success" });
              if (res && res.token) {
                // this.$router.push({
                //   name: "Main",
                // });
                this.$router.back();
              } else {
                // console.log("Login Fail")
                // this.$router.push({
                //   name: "SignUpSocial",
                //   params: { method: "zalo", userid: send_data.userID },
                // });
                this.signUpZalo(zaloProfile)
                // this.signUp(zaloProfile)
                //   .then((res) => {
                //     this.signInZalo({ send_data: send_data }).then(
                //       (response) => {
                //         routerBack.back();
                //       }
                //     );
                //   })
                //   .catch((err) => {
                //     this.$notify({
                //       title: "Error",
                //       text: err?.response?.data?.message,
                //       type: "error",
                //     });
                //   });
              }
            })
            .catch((err) => {
              // this.$router.push({
              //   name: "SignUpSocial",
              //   params: { method: "zalo", userid: send_data.userID },
              // });
              this.signUpZalo(zaloProfile)
            });
        })
        .catch((error) => console.log(error.message));
    },
    // sign up with Zalo
    signUpZalo (profile) {
      this.signUp(profile)
        .then((res) => {
          this.signInZalo({ send_data: {
            userID: profile.social_id
          }}).then((response) => {
            this.$router.back();
          });
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            text: err?.response?.data?.message,
            type: "error",
          });
        });
    },
    // sign up with Apple
    signUpApple (profile) {
      this.signUp(profile)
        .then((res) => {
          this.signInApple({ send_data: {
            userID: profile.social_id
          }}).then((response) => {
            this.$router.back();
          });
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            text: err?.response?.data?.message,
            type: "error",
          });
        });
    }
  },
};
</script>
