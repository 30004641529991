/** @format */

import Vue from "vue";
import App from "./layouts/App";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import axios from "axios";
import Vuelidate from "vuelidate";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css"
import "./assets/scss/style.scss";
import Notifications from "vue-notification";
import GAuth from "vue-google-oauth2";
import VueAppleLogin from "vue-apple-login";
import helper from "@/plugins/helper";
import mixins from "./mixin";
import axiosSetUp from "./plugins/axios";
import moment from "moment";
import VueSocialSharing from "vue-social-sharing";
import VCalendar from "v-calendar";
import VueExpandableImage from "vue-expandable-image";
import Chartkick from "vue-chartkick";
import Chart from "chart.js/auto";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import VueMeta from 'vue-meta';

Vue.use(Multiselect);
Vue.use(VueperSlides);
Vue.use(VueperSlide);
Vue.use(Chartkick.use(Chart));
Vue.use(VueMeta);

import "./assets/slick-1.8.1/slick/slick.min.js";
import "./assets/slick-1.8.1/slick/slick-theme.css";
import "./assets/slick-1.8.1/slick/slick.css";
require("@/store/subscriber");
import zoom from "vue-image-zoom";
Vue.use(zoom);

import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(VueExcelXlsx);

//appleid
Vue.use(VueAppleLogin, {
  //clientId: "MJG65X9S9V.com.lifeonkorea.platform",
  clientId: "com.lifeon.service",
  scope: "name email",
  // redirectURI: "https://life-on.me",
  redirectURI: "https://life-on.me",
  //redirectURI: "https://prod.lifeonkorea.com",
  //redirectURI: "https://localhost:8080",
  state: "initial",
  usePopup: true,
});
//googleid

Vue.use(GAuth, {
  clientId:
    "921522436125-31vep1t5e4frg78bf9n92kqp0ip8p2av.apps.googleusercontent.com",
  scope: "profile email",
  prompt: "select_account",
});
Vue.mixin(mixins);
Vue.prototype.helper = helper;
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Notifications);
Vue.prototype.$http = axios;
Vue.use(Vuelidate);
Vue.use(VueSocialSharing);
// Use v-calendar & v-date-picker components
Vue.use(VCalendar);
Vue.use(VueExpandableImage);
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  let language = to.params.language;
  if (!language) {
    language = "vi";
  }
  i18n.locale = language;
  next();
});
Vue.filter("fiveNumber", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = value.toString();
  var fiveNumber = '';
  for(let i = 0; i < 5 - formatter.length; i++){
    fiveNumber += '0';
  }
  fiveNumber += formatter;
  return fiveNumber;
});
Vue.filter("currency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat();
  return formatter.format(value);
});
Vue.filter("mmyyyy", function (value) {
  if (value) {
    return moment(String(value)).format("MM/YYYY");
  }
});
Vue.filter("ddmmyyyy", function (value) {
  if (value) {
    return moment(String(value)).format("DD, MMM, YYYY");
  }
});
Vue.filter("ddmm", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM");
  }
});
Vue.filter("date", function (value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});
Vue.filter("dotDate", function (value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YYYY");
  }
});
Vue.filter("ddmmyy", function (value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YY");
  }
});
Vue.filter("dateNoSupport", function (value) {
  if (value) {
    return moment(String(value)).format("YYYYMMDD");
  }
});
Vue.filter("datedot", function (value) {
  if (value) {
    return moment(String(value)).format("DD.MM.YYYY");
  }
});
Vue.filter("datedash", function (value) {
  if (value) {
    return moment(String(value)).format("DD-MM-YYYY");
  }
});
Vue.filter("dateItem", function (value) {
  if (value) {
    return moment(String(value)).format("YYYY-MM-DD");
  }
});
Vue.filter("dateStringMonth", function (value) {
  if (value) {
    return moment(String(value)).format("DD MMM YYYY");
  }
});
Vue.filter("monthdot", function (value) {
  if (value) {
    return moment(String(value)).format("MM.YYYY");
  }
});
Vue.filter("yeardot", function (value) {
  if (value) {
    return moment(String(value)).format("YYYY");
  }
});
Vue.filter("timeandfuldate", function (value) {
  if (value) {
    return moment(String(value)).format("HH:mm - DD/MM/YYYY");
  }
});
Vue.filter("timeandfuldatedot", function (value) {
  if (value) {
    return moment(String(value)).format("HH:mm DD.MM.YYYY");
  }
});
Vue.filter("fullDateAndFullTime", function (value) {
  if (value) {
    return moment(String(value)).format("YYYY.MM.DD HH:mm:ss");
  }
});
Vue.filter("timelines", function (value) {
  switch (value) {
    case 1:
      return "Pink";
    case 2:
      return "Green";
    case 3:
      return "Blue";
    default:
      break;
  }
});
Vue.filter("ranking", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  switch (value) {
    case 1:
      return "1st";
    case 2:
      return "2nd";
    case 3:
      return "3rd";
    default:
      break;
  }
});
Vue.filter("timeline", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  const h = value.toFixed(2);
  const sArr = (h + "").split(".");
  let hh = sArr[0];
  let ss = sArr[1];
  hh = hh.length === 1 ? "0" + hh : "" + hh;
  ss = ss.length === 1 ? "0" + ss : "" + ss;
  return hh + ":" + ss;
});
Vue.filter("capitalize", function (value) {
  if (typeof value !== "string") {
    return value;
  }
  return value[0].toUpperCase() + value.substring(1);
});
axiosSetUp();

store.dispatch("auth/getProfile", localStorage.getItem("token")).then(() => {  
  window.app = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
