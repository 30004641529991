import store from "@/store";
import axiosClient from "../plugins/axiosClient";
import qs from "querystringify";

export default {
  namespaced: true,
  state: {
    apartmentList: [],
    styleList: [],
    layoutList: [],
    selected_project: null,
    projectDetail: null,
    projectList: [],
  },
  mutations: {
    GET_APARTMENT_LIST(state, payload) {
      state.apartmentList = payload ? payload : null;
    },
    GET_STYLE_LIST(state, payload) {
      state.styleList = payload ? payload : null;
    },
    LAYOUT_ON_DESIGN(state, payload) {
      state.layoutList = payload ? payload.room_layout_detail : null;
    },
    LAYOUT_ON_DESIGN_NEW(state, payload) {
      state.layoutList = payload ? payload.room_layout_detail : null;
    },
    SELECTED_PROJECT(state, payload) {
      state.selected_project = payload ? payload : null;
    },
    ROOM_BY_ROOM_PROJECT(state, payload) {
      state.projectDetail = payload ? payload : null;
    },
    ROOM_BY_ROOM_PROJECT_SMALL_LIST(state, payload) {
      state.projectList = payload ? payload : null;
    },
  },
  actions: {
    // get Apartment List
    async getApartmentList({ commit, state, dispatch }) {
      try {
        const res = await axiosClient.get(
          `${store.state.urlApi}/app/room-by-room/current-projects`
        );
        if (res && res.data) {
          commit("GET_APARTMENT_LIST", res.data?.data);
        }
      } catch (error) {
        throw error;
      }
    },

    // get Style List For Project
    getStyleListForProject({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axiosClient
          .get(
            `${store.state.urlApi}/app/room-by-room/style-list?project_name=${payload?.name}&tower=${payload.tower}&type=${payload.type}`
          )
          .then((response) => {
            let styleList = [];
                       
            response.data?.data.forEach((item) => {
              if (
                styleList.findIndex((u) => u.style_name === item.style_name) >
                -1
              ) {
                return;
              } else {
                styleList.push(item);
              }
            });
            
            styleList = styleList.concat([
              {
                id: 0,
                style_name: "Rural",
                thumbnail:
                  "https://lifeon-v2.sgp1.digitaloceanspaces.com/interior_design/2023_0_10_11_41_8_986_139.JPG",
              },
              {
                id: 0,
                style_name: "Rustic",
                thumbnail:
                  "https://lifeon-v2.sgp1.digitaloceanspaces.com/interior_design/2023_0_10_14_34_30_802_199.JPG",
              },
              {
                id: 0,
                style_name: "Neo-classic",
                thumbnail:
                  "https://lifeon-v2.sgp1.digitaloceanspaces.com/interior_design/2023_0_11_8_53_29_291_212.JPG",
              },
              {
                id: 0,
                style_name: "Japandi",
                thumbnail:
                  "https://lifeon-v2.sgp1.digitaloceanspaces.com/interior_design/2023_0_10_11_55_10_256_168.JPG",
              },
            ]);
            commit("GET_STYLE_LIST", styleList);
            resolve(styleList);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // find Layout Base on Selected Design
    findLayoutBaseOnDesign({ commit, state, dispatch }, id) {
      return new Promise((resolve, reject) => {
        axiosClient
          .get(`${store.state.urlApi}/app/room-by-room/room-by-design/${id}`)
          .then((response) => {
            commit("LAYOUT_ON_DESIGN", response.data?.data);
            resolve(response?.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // find Layout Base on Selected Design (not Design ID)
    findLayoutBaseOnDesignNew({ commit, state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        axiosClient
          .get(
            `${
              store.state.urlApi
            }/app/room-by-room/room-by-design?${qs.stringify(payload)}`
          )
          .then((response) => {
            commit("LAYOUT_ON_DESIGN_NEW", response.data?.data);
            resolve(response?.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // find Room By Room Project Detail
    findRoomByRoomProjectDetail({ commit, state, dispatch }, id) {
      return new Promise((resolve, reject) => {
        axiosClient
          .get(`${store.state.urlApi}/app/room-by-room/project/${id}`)
          .then((response) => {
            //console.log("ROOM_BY_ROOM_PROJECT", response.data?.data);
            commit("ROOM_BY_ROOM_PROJECT", response.data?.data);
            resolve(response?.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    findRoomByRoomProjectSmallList({ commit, state, dispatch }, id) {
      return new Promise((resolve, reject) => {
        axiosClient
          .get(`${store.state.urlApi}/app/room-by-room/project/small/list?project_id=${id}`)
          .then((response) => {
            commit("ROOM_BY_ROOM_PROJECT_SMALL_LIST", response.data?.data);
            resolve(response?.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
