const paymentRouter = [
  {
    path: 'payment/:type/:id/:payType',
    name: 'Payment',
    component: () => import('@/views/PaymentProduct/Payment'),
  },
  {
    path: 'payment-result/:type',
    name: 'PaymentResult',
    component: () => import('@/views/PaymentProduct/PaymentResult')
  },
  {
    path: 'payment-web-result/:type/:id',
    name: 'PaymentWebResult',
    component: () => import('@/views/PaymentProduct/PaymentWebResult'),
    meta: { requiresAuth: true }
  },
  {
    path: 'payment-success/:type',
    name: 'PaymentSuccess',
    component: () => import('@/views/PaymentProduct/components/PaymentSuccess')
  },
  {
    path: "payment-order-confirm",
    name: "PaymentOrderConfirm",
    component: () => import("@/views/PaymentProduct/components/PaymentOrderConfirm"),
  },
];
export default paymentRouter;
