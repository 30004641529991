import store from "@/store";
import axiosClient from "../plugins/axiosClient";
import qs from "querystringify";
export default {
  namespaced: true,
  state: {
    bestProductList: [],
    alsoLike: [],
    productList: [],
    totalPages: 1,
    offset: 1,
  },
  // getters: {
  //   offset: (state) => {
  //      return state.offset;
  //   },
  // },
  mutations: {
    GET_BEST_PRODUCT_LIST(state, payload) {
      state.bestProductList = payload ? payload : [];
    },
    GET_ALSO_LIKE_PRODUCT_LIST(state, payload) {
        state.alsoLike = payload ? payload : [];
    },
    GET_PRODUCT_LIST(state, payload) {
        state.productList = payload ? payload : [];
    },
    GET_TOTAL_PAGES(state, payload) {
      state.totalPages = payload ? payload : 1;
    },
    SET_OFFSET_PAGE(state, payload) {
      state.offset = payload ? payload : 1;
      console.log("offset: " + state.offset, payload);
    }
  },
  actions: {
    // getBestProductList
    async getBestProductList({ commit, state, dispatch }, payload) {
      try {
        const res = await axiosClient.get(
          `${store.state.url_mall}/api/public/product/product-list?${qs.stringify(payload)}`,
          { headers: { "Accept-Language": store.state.lang } }
        );
        if (res && res.data) {
            let productList = []
            if (window.innerWidth <= 992) {
                let isEven = res.data.data.products.length % 2;
                productList = res.data.data.products.slice(
                  0,
                  res.data.data.products.length - isEven
                );
              } else {
                let isEven = res.data.data.products.length % 3;
                productList = res.data.data.products.slice(
                  0,
                  res.data.data.products.length - isEven
                );
            }
            commit("GET_BEST_PRODUCT_LIST", productList);
        }
      } catch (error) {
        throw error;
      }
    },
    // get product also like
    async getProductAlsoLikeList({ commit, state, dispatch }) {
        try {
          const res = await axiosClient.get(
            `${store.state.url_mall}/api/public/product/you-may-also-like?limit=10`,
            { headers: { "Accept-Language": store.state.lang } }
          );
          if (res && res.data) {
              commit("GET_ALSO_LIKE_PRODUCT_LIST", res.data.data);
          }
        } catch (error) {
          throw error;
        }
    },
    // get All products list
    async getAllProductList({ commit, state, dispatch }, payload) {
        try {
          const res = await axiosClient.get(
            `${store.state.url_mall}/api/public/product/product-list?${qs.stringify(payload)}`,
            { headers: { "Accept-Language": store.state.lang } }
          );
          if (res && res.data) {      
            // console.log('getAllProductList > res.data ', res.data); 
            commit("GET_PRODUCT_LIST", state.productList.concat(res.data.data.products));
            commit("GET_TOTAL_PAGES", res.data.data?.meta?.totalPages);
            // commit("SET_OFFSET_PAGE", state.offset + 1);
          }
        } catch (error) {
          throw error;
        }
    },
  },
};
