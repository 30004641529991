<template>
  <div class="upload-image">
    <div class="upload-image-item" v-for="(url, index) in imageUrls" :key="index">
      <div class="custom-img">
        <img :src="url" alt="" />
      </div>
      <div class="delete-icon" @click="handleDelete(index)">
        <img
                class="icon"
                src="@/assets/images/icons/close.svg"
                alt="close svg"
              />
      </div>
    </div>
    <label class="btn-upload" v-if="imageUrls.length < imgNumber">
      <img src="@/assets/images/icons/add-file.svg" alt="Attach File" />
      <input
        type="file"
        accept="image/*"
        multiple
        style="display: none"
        v-on:change="previewFiles($event)"
        @click="$event.target.value = null"
        :disabled="imageUrls.length >= 10"
      />
    </label>
  </div>
</template>
<style lang="scss" scoped>
@import "~sass-rem";
@import "@/assets/scss/upload-image.scss";
</style>
<script>
export default {
  name: "UploadImage",
  props: ['imgCount'],
  data() {
    return {
      imageUrls: [],
    };
  },
  created() {},
  methods: {
    previewFiles(event) {
      let files = event?.target?.files;
      Object.keys(files).forEach((key) => {
        if(this.imageUrls.length+Number(key) < this.imgNumber) {
          this.$emit("image-change", files[key]);
          this.getImageUrl(files[key]);
        }
    });
      // event?.target?.files?.map(file => {
      //   this.$emit("image-change", file);
      //   this.getImageUrl(file);
      // })
      
    },
    getImageUrl(file) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          // convert image file to base64 string
          this.imageUrls.push(reader.result);
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    },
    handleDelete(index) {
      this.imageUrls.splice(index, 1);
      this.$emit("on-delete", index);
    },
  },
  computed: {
    imgNumber() {
      return this.imgCount ? this.imgCount : 100;
    }
  },
  mounted() {},
  watch: { },
};
</script>
