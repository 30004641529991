const careerRouter = [
  {
    path: 'career',
    name: 'Career',
    component: () => import('@/views/Career/Career')
  },
  {
    path: 'career-detail/:id',
    name: 'CareerDetail',
    component: () => import('@/views/Career/CareerDetail'),
    meta: { requiresAuth: true }
  }
];
export default careerRouter;
