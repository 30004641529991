/** @format */

const interiorShareRouter = [
  {
    path: 'interior-share',
    name: 'InteriorShare',
    component: () => import('@/views/InteriorShare/InteriorShare'),
  },
  {
    path: 'interior-share/page-:page',
    name: 'InteriorSharePage',
    component: () => import('@/views/InteriorShare/InteriorShare'),
  },
  {
    path: 'interior-share/my-post',
    name: 'MyPost',
    component: () => import('@/views/InteriorShare/MyPost'),
    meta: { requiresAuth: true },
  },
  {
    path: 'interior-share/write-post',
    name: 'WritePost',
    component: () => import('@/views/InteriorShare/WritePost'),
    meta: { requiresAuth: true },
  },
  {
    path: 'interior-share/edit-post/:id',
    name: 'EditInteriorShare',
    component: () => import('@/views/InteriorShare/EditInteriorShare'),
    meta: { requiresAuth: true },
  },
  {
    path: 'interior-share/:slug',
    name: 'InteriorShareDetail',
    component: () => import('@/views/InteriorShare/InteriorShareDetail'),
  },
]
export default interiorShareRouter
