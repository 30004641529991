const signupRouter = [
  {
    path: "sign-up",
    name: "Sign Up",
    component: () => import("@/views/SignUp/SignUp"),
    meta: { requiresAuth: false },
  },
  {
    path: "sign-up-with-member",
    name: "Sign Up With Member",
    component: () => import("@/views/SignUp/Member"),
  },
  {
    path: "signup-social",
    name: "SignUpSocial",
    component: () => import("@/views/SignUp/SignUpSocial"),
  },
  {
    path: "registration-form",
    name: "AffiliateRegistrationForm",
    component: () => import("@/views/SignUp/AffiliateRegistrationForm"),
  },
  {
    path: "bulk-order-inquiry",
    name: "BulkOrderInquiry",
    component: () => import("@/views/SignUp/BulkOrderInquiry"),
    meta: { requiresAuth: true },
  },
  {
    path: "sign-up-with-business-member",
    name: "Sign Up With Business Member",
    component: () => import("@/views/SignUp/BusinessMember"),
  },
  {
    path: "sign-up-success/:type",
    name: "Sign Up Success",
    component: () => import("@/views/SignUp/Success"),
  },
  {
    path: "marketing-campaign-form",
    name: "Marketing Campaign Form",
    component: () => import("@/views/SignUp/FreeTrialForm/MarketingCampaign"),
  },
  {
    path: "free-trial-project/:id",
    name: "Project List",
    component: () => import("@/views/SignUp/FreeTrialForm/ProjectList"),
  },
  {
    path: "free-trial-tax/:id",
    name: "Tax Form",
    component: () => import("@/views/SignUp/FreeTrialForm/TaxForm"),
  },
  {
    path: "delete-user-account",
    name: "DeleteAccount",
    component: () => import("@/views/SignUp/DeleteAccount"),
  },
];
export default signupRouter;
