import axios from "axios";
import store from "@/store";
import Vue from "vue";

export default function axiosSetUp() {
    axios.interceptors.response.use(
        response => {
            store.state.isLoading = false;
            return response;
        },
        async error => {
            store.state.isLoading = false;
            return error;
            // console.log("err======", error)
            // return Vue.notify({ title: 'Error',text: error?.response?.data?.message , type: 'error', });
        }
    );
}