import axios from "axios";
const axiosClient = axios.create({
    // baseURL: store.state.urlApi,
});
axiosClient.interceptors.request.use((config) => {
  if (localStorage.getItem("token")) {
    config.headers.common.Authorization = `Bearer ${localStorage.getItem("token")}`;
  }
  return config;
});

export default axiosClient;