import store from "@/store";
import router from "@/router";
import mixpanel from "mixpanel-browser";


export default {
  methods: {
    callAPI(link = "", method = "get", data = {}, headers = {}) {
      this.$store.state.isLoading = true;
      var link_api = this.$store.state.urlApi + link;
      // delete method with body
      if (method === "get") {
        headers["Accept-Language"] = store.state.lang;
        if (!headers["Authorization"]) {
          headers["Authorization"] = `Bearer ${this.$store.state.auth.token}`;
        }
        if (!this.$store?.state?.auth?.token) {
          delete headers["Authorization"];
        }
        headers = { ...headers };
        return this.$http[method](link_api, { headers: headers });
      }
      if (method === "deleteBody") {
        return this.$http.delete(link_api, {
          headers,
          data,
        });
      }
      return this.$http[method](link_api, data, { headers: headers });
    },
    callAPI_brickmate(link = "", method = "get", data = {}, headers = {}) {
      this.$store.state.isLoading = true;
      var link_api = this.$store.state.url_mall + link;
      // delete method with body
      if (method === "get") {
        headers["Accept-Language"] = store.state.lang;
        headers[
          "Authorization"
        ] = `Bearer ${this.$store.state.auth.token_commerce}`;
        if (!this.$store?.state?.auth?.token) {
          delete headers["Authorization"];
        }
        headers = { ...headers };
        return this.$http[method](link_api, { headers: headers });
      }
      if (method === "deleteBody") {
        return this.$http.delete(link_api, {
          headers,
          data,
        });
      }
      return this.$http[method](link_api, data, { headers: headers });
    },
    validateAuth(cb) {
      if (store.state.auth.token && cb) {
        cb();
      } else {
        router.push({ name: "Login" });
      }
    },
    callMultipleAPI(multipleRequest = []) {
      return this.$http
        .all(multipleRequest)
        .then(
          this.$http.spread((...responses) => {
            return responses.map((rs) =>
              rs && rs.data && rs.data.data ? rs.data.data : []
            );
          })
        )
        .catch((errors) => {
          return null;
        });
    },
    getMembershipType() {
      const membership = this.$store.state.auth?.profileInfo?.user_membership;
      if (membership && membership.type === "Gold") {
        if (membership.is_auction) {
          return "Premium";
        } else {
          return "Gold";
        }
      } else if (membership && membership.type === "Basic") {
        return "Basic";
      }
      return null;
    },
    // banner - design
    getImageUrl(url, width, height) {
      if (!url)
        return "https://lifeon-v2.sgp1.digitaloceanspaces.com/banner/2023_3_3_15_6_52_756_default.jpg";
      // let screenWidth = window.innerWidth;
      let widthImg = width;
      let heightImg = height > 0 ? height : "AUTO";

      // if (width >= 1000 && screenWidth < 1000) {
      //   widthImg = screenWidth;
      // }
      let link = url.split("/");
      link.splice(link.length - 1, 0, widthImg + "x" + heightImg);
      if (link[2].includes("s3")) {
        link.splice(2, 1, "lifeon-v2.sgp1.digitaloceanspaces.com");
      }
      return link.join("/");
    },
    // get resize image Url:
    getResizeImageUrl(url, width, height) {
      if (!url)
        return "https://lifeon-v2.sgp1.digitaloceanspaces.com/banner/2023_3_3_15_6_52_756_default.jpg";
      let widthImg = width;
      let heightImg = height ? height : "";
      if (url.includes("lifeon-v2.sgp1.cdn.digitaloceanspaces.com")) {
        return url.replace("lifeon-v2.sgp1.cdn.digitaloceanspaces.com", "ik.imagekit.io/lifeon") + "?tr=w-" + widthImg + ",h-" + heightImg;
      } else {
        return url.replace("lifeon-v2.sgp1.digitaloceanspaces.com", "ik.imagekit.io/lifeon") + "?tr=w-" + widthImg + ",h-" + heightImg;
      }
    },
    // carousel
    getImage(containerNAME, imageName, width, height) {
      let widthImg = width;
      let heightImg = height > 0 ? height : "AUTO";

      let url = `${this.$store.state.url_mall}/api/media/image-resize?path=${containerNAME}&name=${imageName}&width=${widthImg}&height=${heightImg}`;
      return url;
    },
    // carousel design
    getImageParams(url, width, height) {
      const urlParams = new URLSearchParams(url);
      const wParam = urlParams.get("width");
      const hParam = urlParams.get("height");
      return url.replace(
        `width=${wParam}&height=${hParam}`,
        `width=${width}&height=${height > 0 ? height : "AUTO"}`
      );
    },
    getImageByLanguages(
      container,
      image,
      containerEn,
      imageEn,
      containerKo,
      imageKo,
      width,
      height
    ) {
      let screenWidth = window.innerWidth;
      let widthImg = width;
      let heightImg = height > 0 ? height : "AUTO";

      if (width >= 1000 && screenWidth < 1000) {
        widthImg = screenWidth;
      }

      let lang = store.state.lang;
      if (lang === "vi" && image)
        return `${this.$store.state.url_mall}/api/media/image-resize?path=${container}&name=${image}&width=${widthImg}&height=${heightImg}`;
      if (lang === "en" && imageEn)
        return `${this.$store.state.url_mall}/api/media/image-resize?path=${containerEn}&name=${imageEn}&width=${widthImg}&height=${heightImg}`;
      if (lang === "ko" && imageKo)
        return `${this.$store.state.url_mall}/api/media/image-resize?path=${containerKo}&name=${imageKo}&width=${widthImg}&height=${heightImg}`;

      return `${this.$store.state.url_mall}/api/media/image-resize?path=${container}&name=${image}&width=${widthImg}&height=${heightImg}`;
    },
    getContentByLanguages(content_vi, content_en, content_ko) {
      let lang = store.state.lang;
      if (lang === "vi" && content_vi) return content_vi;
      if (lang === "en" && content_en) return content_en;
      if (lang === "ko" && content_ko) return content_ko;
      return content_vi || content_en || content_ko;
    },
    pushMixPanel(eventName, body) {
      mixpanel.init(`${this.$store.state.mixpanel}`, { debug: true });
      mixpanel.track(eventName, body);
    },
    isMobile () {
      return window.innerWidth < 768;
    }
  },
};
