import store from "@/store";
import axiosClient from "../plugins/axiosClient";
import qs from "querystringify";

export default {
  namespaced: true,
  state: {
    articleList: [],
  },
  mutations: {
    GET_ARTICLE_LIST(state, payload) {
      state.articleList = payload ? payload : null;
    },
  },
  actions: {
    async getArticleList({ commit, state, dispatch }, payload) {
      try {
        const res = await axiosClient.get(
            `${store.state.urlApi}/admin/article/list?${qs.stringify(payload)}`,
          { headers: { "Accept-Language": store.state.lang } }
        );
        if (res && res.data) {
          console.log("my-other-data", res.data.data)
          commit("GET_ARTICLE_LIST", res.data.data);
        }
      } catch (error) {
        throw error;
      }
    },
  },
};
