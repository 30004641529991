import store from '@/store';

store.subscribe(mutation => {
  switch (mutation.type) {
    case 'auth/SET_TOKEN':
      if (mutation.payload) {
        localStorage.setItem('token', mutation.payload.token);
      } else {
        localStorage.removeItem('token');
      }
      break;
    case 'auth/SET_TOKEN_COMMERCE': 
      if (mutation.payload) {
        localStorage.setItem('token_commerce', mutation.payload.commerce_token);
        localStorage.setItem('token_commerce_partner', mutation.payload.commerce_partner_token);
      } else {
        localStorage.removeItem('token_commerce');
        localStorage.removeItem('token_commerce_partner');
      }
      break;
  }
});
