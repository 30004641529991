import store from "@/store";
import axiosClient from "../plugins/axiosClient";
import qs from "querystringify";

export default {
  namespaced: true,
  state: {
    bannerList: [],
  },
  mutations: {
    GET_BANNER_SLIDE(state, payload) {
      state.bannerList = payload ? payload : null;
    },
  },
  actions: {
    // getBannerSlide
    async getBannerSlide({ commit, state, dispatch }, payload) {
      try {
        const res = await axiosClient.get(
          `${store.state.url_mall}/api/public/banner/banner-list?${qs.stringify(payload)}`,
          { headers: { "Accept-Language": store.state.lang } }
        );
        if (res && res.data) {
            commit("GET_BANNER_SLIDE", res.data.data.items);
        }
      } catch (error) {
        throw error;
      }
    },
  },
};
