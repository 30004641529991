import store from "@/store";
import axiosClient from "../plugins/axiosClient";
import qs from "querystringify";

export default {
  namespaced: true,
  state: {
    bestFurnitureDecors: [],
    simpleFurnitureDecors: [],
  },
//   getters: {
//     getInfoToken: (state) => {
//       return state.token;
//     },
//     getBaseURL: (state) => {
//       return state.urlApi;
//     }
//   },
  mutations: {
    GET_FURNITURE_DECOR_LIST(state, payload) {
      state.bestFurnitureDecors = payload ? payload.map((item) => ({
        ...item,
        thumbnail_item: { ...item?.thumbnail_item, content_lang: item?.thumbnail_item?.content_lang.replace(/<[^>]+>/g, "")},
      })) : null;
    },
    GET_SIMPLE_FURNITURE_DECOR_LIST(state, payload) {
      state.simpleFurnitureDecors = payload ? payload.map((item) => ({
        ...item,
        thumbnail_item: { ...item?.thumbnail_item, content_lang: item?.thumbnail_item?.content_lang.replace(/<[^>]+>/g, "")},
      })) : null;
    },
  },
  actions: {
    // getBestFurnitureDecorList
    async getBestFurnitureDecorList({ commit, state, dispatch }) {
      try {
        const res = await axiosClient.get(
          `${store.state.urlApi}/app/furniture-decor/list?page=1&limit=4`,
          { headers: { "Accept-Language": store.state.lang } }
        );
        if (res && res.data) {
          commit("GET_FURNITURE_DECOR_LIST", res.data.data);
        }
      } catch (error) {
        throw error;
      }
    },
    async getSimpleFurnitureDecorList({ commit, state, dispatch }, payload) {
      try {
        const res = await axiosClient.get(
          `${store.state.urlApi}/app/furniture-decor/simple/list?${qs.stringify(payload)}`,
          { headers: { "Accept-Language": store.state.lang } }
        );
        if (res && res.data) {
          commit("GET_SIMPLE_FURNITURE_DECOR_LIST", res.data.data);
        }
      } catch (error) {
        throw error;
      }
    },
  },
};
